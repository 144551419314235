import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"

import Layout from "../../components/layout"
import { Seo } from "../../components/seo"
import Subscribe from "../../components/subscribe"
import Footer from "../../components/footer"

const EpisodePage = () => (
    <Layout>
        <div className="episode-detail-wrapper">
            <header>
                <StaticImage
                    src="../../images/vamp-poster.jpeg"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="A poster for the 1986 horror comedy, Vamp"
                    className="img-fluid"
                />
            </header>
            <div className="episode-detail-body container">
                <div className="row episode-detail-title text-center py-5">
                    <h1>Episode 09</h1>
                    <h2>Vamp</h2>
                    <h3>Jun 12, 2023</h3>
                </div>
                <div className="row episode-detail-plot boxed-in">
                    <div class="indent-this py-5">
                        <p><b>Director:</b> Richard Wenk // <b>Starring:</b> Chris Makepeace &amp; Grace Jones</p>
                        <div className="player">
                        <iframe src="https://podcasters.spotify.com/pod/show/bryan-jude-white/embed/episodes/09-Vamp-e25k9tl" height="102px" width="100%" frameborder="0" scrolling="no" title="Bring Me The Axe Horror Podcast episode 09, Vamp"></iframe>
                        </div>
                        <p><Link to="/transcripts/vamp">Read the episode transcript</Link></p>
                        <p>Bring Me The Axe kicks off its Pride 2023 series with a mostly unremarkable movie that remains in the horror movie memory thanks entirely to contributions from gay artists, the 1986 horror comedy Vamp, starring Chris Makepeace, Robert Rusler, and Grace Jones. In an attempt to make a story about two very good friends trying to wheel and deal their way into a college fraternity, writer/director, Richard Wenk, accidentally tips the movie into queer territory with a story about the unrequited love of one man for another. You'll also learn way more about Keith Haring and Robert Mapplethorpe than you were probably expecting to in a chat about how awesome Grace Jones is.</p>
                        <p><OutboundLink href="https://www.youtube.com/watch?v=pk39I43qto0" target="_blank" rel="noreferrer">Trailer</OutboundLink></p>
                        <p><OutboundLink href="https://www.youtube.com/watch?v=UtK9UpOFG78" target="_blank" rel="noreferrer">Interview with produce Donald Borchers</OutboundLink></p>
                        <p><OutboundLink href="https://www.youtube.com/watch?v=hUIm51kB0u4" target="_blank" rel="noreferrer">Dracula Bites The Big Apple</OutboundLink></p>
                    </div>
                </div>
                <div className="row">
                    <Subscribe />
                </div>
                <div className="row previous-next indent-this">
                    <ul>
                        <li><Link to="/episodes/the-old-dark-house">Next Episode</Link></li>
                        <li><Link to="/episodes/the-house-on-sorority-row">Previous Episode</Link></li>
                    </ul>
                </div>
                <Footer />
            </div>
        </div>
    </Layout>
)

export default EpisodePage

export const Head = () => (
    <Seo />
)